import ModelListCommon from "./ModelListCommon";

import UserConnector from "./connectors/UserConnector";
import Player from "./model/Types/Player";
import { IUserInfo } from "./connectors/SocketConnector";
import ArraySortedPlayer from "./ArraySortedPlayer";
import PlayerConnector from "./connectors/PlayerConnector";


export default class ModelListPlayer extends ModelListCommon {

    //player?:Player
    connector:UserConnector
    connectorPlayer:PlayerConnector
    models:ArraySortedPlayer
                
    constructor(userConnector:UserConnector ) {           
        super(userConnector);
        //this.player = undefined;
        this.connector = userConnector;         
        this.connectorPlayer = new PlayerConnector(this.socket);
        this.models = new ArraySortedPlayer();          
        
        this.connector.on(UserConnector.EVENT_USER_INFO, (data: IUserInfo)=>{            
            this.onChanged(data);            
        })
    }

    getEventData():any { return this.models; }
    getConnector():PlayerConnector { return this.connectorPlayer; }
            
    async doDataClear() {  
        super.doDataClear();      
        this.models.clear();
        this.onChanged();
    }    

    async doDataFetch() {             
        super.doDataFetch();         
    }

    async onChanged(data?: IUserInfo) {          
        if(data)
            this.models.update(data);
        this.emit(ModelListCommon.EVENT_DATA_CHANGED, this.getEventData());       
    }


    async getStatistics( uuid : string ) {
        let result_uuid = uuid
        if ( ! result_uuid ) {
            if ( ! this.models.player ) return
            result_uuid = this.models.player.uuid
        }
        
        let result = await this.connectorPlayer.doGetStatistics( result_uuid );
        return result.statistic
    }    
}
