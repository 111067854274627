/**
 * SocketConnector class.
 *  Base class for all socket based connectors
 *
 * @constructor
 * @param {Object} socket - scoketio socket.
 */

import EventEmitter from 'eventemitter3'


import { IPlayerWithAccount } from "../model/Types/Player"


// this.SORT_DESC = 3;  // TODO static
// this.SORT_ASC = 4;

enum ESortDirection {
    DESC = 3,
    ASC  = 4,
}


export interface IEventData {
    meta: {
        timestamp: number //unix timextamp utc        
    }
}

export interface ISearchPagination {
    page:number
    pageSize:number
    sort?: {[key:string]: ESortDirection }
}

export interface ISearchPagination {
    page:number
    pageSize:number
    sort?: {[key:string]: ESortDirection }
}

export interface ISearchResult<T> {
    meta: {
        timestamp:number
    },
    models: T[]
    pagination: {
        page:number
        pageCount:number
        pageSize:number
        totalCount:number
    }
    sort: {[key:string]: ESortDirection}
}

export interface IUserInfo {    
    uuid: string
    isAdmin: boolean
    logined: boolean
    player: IPlayerWithAccount
    cookie?: any[]
    session?: {
        id:string
        name?:string        
    }
}


export default class SocketConnector extends EventEmitter {
    socket:SocketIOClient.Socket        
    verbose:boolean
    verbose_name:string
    emit_name:string
    controller_id:string | null
    proceed_errors:boolean
    myUuid?:string
    
    static readonly EMIT_NAME_PENDING_START = "pending_start"
    static readonly EMIT_NAME_PENDING_STOP  = "pending_stop"

    constructor(socket:SocketIOClient.Socket) {
        super();
        
        this.socket = socket;
        this.verbose = process.env.BG_CONNECTOR_VERBOSE == '1';
        this.verbose_name = "SocketConnector:";
        this.emit_name = "frontendRpc";
        this.controller_id = null;
        //this.__call = this.__callSocketRpc;
        this.proceed_errors = true;     
        this.myUuid = undefined; 
        
        this.socket.on("user_info", (data:IUserInfo) => {     
            this.myUuid  = data.uuid;  
        });
        
    }
    
    __log(...args:any[]) {                             
        // bind нужен для старого хрома http://qaru.site/questions/622200/why-i-cant-pass-consolelog-as-a-callback-argument-in-chrome-and-safari
        console.log.bind(console).apply<any,any[],any>(null,args);        
    };

    async __call(method:string, params?:object):Promise<any> {
        return this.__callSocketRpc(method, params);
    }

    async wrapLoading( func: ()=>Promise<any> ) {
        try {
            this.emit(SocketConnector.EMIT_NAME_PENDING_START)
            return await func();
        } 
        finally {
            this.emit(SocketConnector.EMIT_NAME_PENDING_STOP)
        }
    } 

    async emitPromise(name:string, params:any):Promise<any> {
        return await this.wrapLoading( () => {
            return this.emitInternal(name, params)
        });
    }

    private emitInternal(name:string, params:any):Promise<any> {
        return new Promise( (resolve, reject) => {
            this.socket.emit( name , params, (e:any,r:any) => {            
                if(e)
                    reject(e);
                else
                    resolve(r);
            });
        });
    }

    async __callSocketRpc(method:string, params?:object) {
        this.__log(this.verbose_name, "emit", method, params);        
        try {        
            let paramsEmit = {
                controller_id: this.controller_id,
                method: method,
                params: params
            }
            let result = await this.emitPromise(this.emit_name, paramsEmit);
            this.onResult(result , method);
            return result;
        } catch(error) {                    
            if(this.onError(error, method))
                throw error 
        }        
    };

    onResult(result:any,method:string) {                
        this.__log(this.verbose_name,"result ("+method+")", result);        
    };

    onError(err:any, method:string) {
        this.__log(this.verbose_name,"error ("+method+")", err);
        return this.proceed_errors;
    };

}



  
  
// socket.emitAsync = function(event, data) {
//     return new Promise((resolve, reject) => {
//         if (!this) {
//             reject('No socket connection.');
//         } else {
//             loggerLocal.debug("emit event ",event);
//             this.emit(event, data, (err, resp) => {
//                 if (err) {                    
//                     loggerLocal.error("emmit error ", event, err);
//                     reject(err);
//                 } else {
//                     loggerLocal.debug("event resolve ", event, resp);
//                     resolve(resp);
//                 }
//             });
//         }
//     });
// }