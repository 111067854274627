import SocketConnector from "./SocketConnector"
import { IPlayer } from "../model/Types/Player";
import { IPlayerStatistics } from "../model/Types/IPlayerStatistics";
import { IPlayerSettings } from "../model/Types/IPlayerSettings";


interface IPlayerList<N>  {
    uuid:string
    name:string
    avatarUri:string
    countryCode:string
    N:{
        value:number
    }      
}

export default class PlayerConnector extends SocketConnector {
    
    cb_unauthorised: (data:any) => void
    
    constructor(socket:SocketIOClient.Socket)  {
        super(socket);

        this.verbose_name = "PlayerConnector:";
        this.controller_id = "player-rpc";
                                                            
        this.cb_unauthorised = (data:any) => {this.__log("cb_unauthorised not used", data); };
    
        this.__setupCallbacks();        
    }

    __setupCallbacks() {            
                        
        this.socket.on("unauthorised", (data:any) => {
            this.__log("unauthorised", data);
            this.cb_unauthorised(data);
        });                
    };


    async doGetTopRating(limit:number):Promise<IPlayerList< "accountRate" >[]> {
        return this.__call("get-top-rating", {limit:limit});
    };


    async doGetTopGamesWin(limit:number):Promise<IPlayerList< "counterGamesWin" >[]> {
        return this.__call("get-top-games-win", {limit:limit});
    };

    // __onUnauthorised(data) {
    //     this.cb_unauthorised(data);
    // };


    /**
     * Get detail information about player
     * @param uuid
     * @param cb
     */
    doView(uuid:string):Promise<IPlayer>{
        return this.__call("view", {uuid:uuid});
    };


    /**
     * Get detail information about player
     * @param {string[]}extra_fields
     * @param cb
     */
    doViewSelf( extra_fields:string[]):Promise<IPlayer> {
        return this.__call("view-self", {extra_fields:extra_fields});
    };


    /**
     * setSettings of player
     *  @param settings {Object}
     *  @param settings.avatar_type  @see \frontend\models\ProfileSettingsForm::$AVATAR_TYPE_VARIANTS
     *  @param settings.color
     *  @param settings.feature_auto_dice
     *  @param settings.feature_double_click_move_type
     *  @param settings.feature_auto_bearing_off
     *
     *  @param settings.sound_enable  boolean
     *  @param settings.country_code  string len = 3 ISO_3166-1
     *
     *  @returns Promise
     */
    doSetSettings( settings:IPlayerSettings) {
        return this.__call("set-settings", settings);
    };


    /**
    * get player statistics     
    */
    doGetStatistics( playerUuid:string ):Promise<{statistic:IPlayerStatistics}> {
        return this.__call("get-statistics", {uuid:playerUuid});
    };

            
    setCallbackOnUnauthorised(cb:(data:any)=>void) { 
        this.cb_unauthorised = cb; 
    };

}


    






