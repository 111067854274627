import SocketIOClient from "socket.io-client"

import UserConnector from "./connectors/UserConnector";
import EventEmitter from 'eventemitter3'

// declare interface UserConnector {        
//     socket:SocketIOClient.Socket
//     on(event:string, cb: (...data:any[]) => void ):any;
//     emit(event:string, ...data: any[]):any;


//     readonly EVENT_USER_INFO:string;
//     readonly EVENT_AUTH_CHANGED:string;
// }


export default class ModelListCommon extends EventEmitter {

    static readonly EVENT_DATA_POPULATED = "data_populated";
    static readonly EVENT_DATA_CHANGED   = "data_changed";
    static readonly EVENT_DATA_CLEAR     = "data_clear";

    
    socket: SocketIOClient.Socket
    playerUuid?: string
    loggerName?: string
    filled: boolean
    
    constructor( userConnector:UserConnector ) {       
        super(); 
        this.socket = userConnector.socket;        
        userConnector.on(UserConnector.EVENT_USER_INFO   , (data)=> { this.onUserInfo(data) } );        
        userConnector.on(UserConnector.EVENT_AUTH_CHANGED, ()=> { this.doDataClear() } );        
        this.socket.on("disconnect", ()=> {this.onDisconnect() });
        this.init();  
        this.filled = false;      
    }

    protected getEventData():any {
        return this;
    }

    init() {

    }
    async onDisconnect() {
        return this.doDataClear();
    } 
    async doDataClear() {   
        this.emit(ModelListCommon.EVENT_DATA_CLEAR, this.getEventData());       
                
        this.playerUuid = undefined;
        this.log("data clear");
        this.filled = false;
    }
    async doDataFetch() {        
        this.filled = true;
        this.log("start fetch data");
    }

    async onUserInfo(data:any) {                
        await this.doDataClear();
        this.playerUuid = data.uuid; 
        //this.log("player uuid = ", this.playerUuid, "data = ", data);        
        if(data.logined) {
            await this.doDataFetch();                
            this.emit(ModelListCommon.EVENT_DATA_POPULATED, this.getEventData());
        }                
    }
    
    log(...args:any[]) {         
        if(this.loggerName)
            args.unshift(this.loggerName+":"); 
        console.log.bind(console).apply<any,any[],any>(null, args);
    }    
}